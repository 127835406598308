import { createAction, props } from '@ngrx/store';
import { InsuranceTaxDto, PaymentTypeDto } from 'src/app/shared/nswag.api';

export const getAllPaymentTypesRequest = createAction('[PaymentTypes] getAllPaymentTypes Request');
export const getAllPaymentTypesSuccess = createAction(
  '[PaymentTypes] getAllPaymentTypes Success',
  props<{
    paymentTypes: PaymentTypeDto[];
  }>(),
);
export const getAllPaymentTypesFailure = createAction(
  '[PaymentTypes] getAllPaymentTypes Failure',
  props<{
    error: string;
  }>(),
);
export const paymentTypeActionRequest = createAction('[PaymentTypes] paymentTypeActionRequest');

export const getInsuraceTaxesRequest = createAction(
  '[InsuranceTaxes] getInsuraceTaxes Request',
  props<{
    period: number | undefined;
  }>(),
);
export const getInsuraceTaxesSuccess = createAction(
  '[InsuranceTaxes] getInsuraceTaxes Success',
  props<{
    insuraceTaxes: InsuranceTaxDto;
  }>(),
);
export const getInsuraceTaxesFailure = createAction(
  '[InsuranceTaxes] getAllPaymentTypes Failure',
  props<{
    error: string;
  }>(),
);
export const onEditInsuranceTax = createAction('[InsuranceTaxes] EditInsuranceTax ');
export const resetInsuranceTax = createAction('[InsuranceTaxes] resetInsuranceTax ');
export const onDeactivatePaymentType = createAction('[InsuranceTaxes] on Deactivate PaymentType ');
