import { createAction, props } from '@ngrx/store';
import {
  ItemAccountingSchemeDto,
  GlAccountDto,
  UnitOfMeasureDto,
  FixedAssetCategoryDto,
  ItemGroupDto,
  ItemCategoryDto,
} from 'src/app/shared/nswag.api';
import { HttpContext } from '@angular/common/http';

export const ItemAccountingScheme = {
  failure: createAction('[ItemAccountingSchemeDto] Cache Failure'),
  skip: createAction('[ItemAccountingSchemeDto] Cache Skip'),
  byNo: createAction('[ItemAccountingSchemeDto] Cache By No', props<{ schemeId: number; httpContext?: HttpContext }>()),
  byNoSuccess: createAction('[ItemAccountingSchemeDto] Cache By No Success', props<{ scheme: ItemAccountingSchemeDto }>()),
};
export const ItemGoup = {
  failure: createAction('[ItemGroupDto] Cache Failure'),
  skip: createAction('[ItemGroupDto] Cache Skip'),
  byNo: createAction('[ItemGroupDto] Cache By No', props<{ groupId: number; httpContext?: HttpContext }>()),
  byNoSuccess: createAction('[ItemGroupDto] Cache By No Success', props<{ itemGroup: ItemGroupDto }>()),
};

export const ItemCategory = {
  failure: createAction('[ItemCategoryDto] Cache Failure'),
  skip: createAction('[ItemCategoryDto] Cache Skip'),
  byNo: createAction('[ItemCategoryDto] Cache By No', props<{ categoryId: number; httpContext?: HttpContext }>()),
  byNoSuccess: createAction('[ItemCategoryDto] Cache By No Success', props<{ itemCategory: ItemCategoryDto }>()),
};

export const AccountingScheme = {
  failure: createAction('[AccountingScheme] Cache Failure'),
  skip: createAction('[AccountingScheme] Cache Skip'),
  byNo: createAction('[AccountingScheme] Cache By No', props<{ schemeId: string }>()),
  byNoSuccess: createAction('[AccountingScheme] Cache By No Success', props<{ scheme: { [key: string]: string | undefined } }>()),
};

export const AssetCategory = {
  failure: createAction('[AssetCategory] Cache Failure'),
  skip: createAction('[AssetCategory] Cache Skip'),
  byNo: createAction('[AssetCategory] Cache By No', props<{ categoryId: string }>()),
  byNoSuccess: createAction('[AssetCategory] Cache By No Success', props<{ category: { [key: string]: string | undefined } }>()),
};

export const AssetGroup = {
  failure: createAction('[AssetGroup] Cache Failure'),
  skip: createAction('[AssetGroup] Cache Skip'),
  byNo: createAction('[AssetGroup] Cache By No', props<{ groupId: string }>()),
  byNoSuccess: createAction('[AssetGroup] Cache By No Success', props<{ group: { [key: string]: string | undefined } }>()),
};

export const CacheGlAccount = {
  failure: createAction('[GlAccountDto] Cache Failure'),
  skip: createAction('[GlAccountDto] Cache Skip'),
  byNo: createAction('[GlAccountDto] Cache By No', props<{ accountId: number; httpContext?: HttpContext }>()),
  byNoSuccess: createAction('[GlAccountDto] Cache By No Success', props<{ account: GlAccountDto }>()),
};

export const CacheUnits = {
  failure: createAction('[Units] Cache Failure'),
  skip: createAction('[Units] Cache Skip'),
  byNo: createAction('[Units] Cache By No', props<{ UnitId: string; httpContext?: HttpContext }>()),
  byNoSuccess: createAction('[Units] Cache By No Success', props<{ Unit: UnitOfMeasureDto }>()),
};

export const CacheParentCategory = {
  failure: createAction('[FixedAssetCategoryDto] Cache Failure'),
  skip: createAction('[FixedAssetCategoryDto] Cache Skip'),
  byNo: createAction('[FixedAssetCategoryDto] Cache By No', props<{ parentId: number; httpContext?: HttpContext }>()),
  byNoSuccess: createAction('[FixedAssetCategoryDto] Cache By No Success', props<{ parent: FixedAssetCategoryDto }>()),
};

// Clear Cache Actions
export const ClearGlAccountsCache = createAction('[Inventory] Clear GlAccounts Cache');
export const ClearVatCache = createAction('[Inventory] Clear Vat Cache');
export const ClearUnitCache = createAction('[Inventory] Clear Unit Cache');
export const ClearLocationCache = createAction('[Inventory] Clear Location Cache');
export const ClearItemSchemesCache = createAction('[Inventory] Clear ItemSchemes Cache');
export const ClearInventoryCache = createAction('[Inventory] Clear Inventory Cache');
